import React from 'react'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'

const NotFoundPage = ({ data }) => {
  return (
    <Layout>
      <section>
        Sorry, can't find the page you're looking for. Enjoy Laurie on Corgi
        instead!
        <StaticImage
          src="../data/headers/laurieoncorgi.jpg"
          alt="Laurie on corgi"
        />{' '}
      </section>
    </Layout>
  )
}

export default NotFoundPage
